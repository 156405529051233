<template>
  <div class="auth-page">
    <div class="auth-container bg"></div>
    <div class="auth-container">
      <div class="form-container login-container" id="login-form">
        <div class="auth-header">
          <router-link to="/"><img class="logo" src="@/assets/image/logo.svg"></router-link>
          <p>Artify your art collections</p>
        </div>
        <div class="form-group" id="form-group-username">
          <label class="col-form-label">Email</label>
          <input class="form-control" type="text" v-model="username" placeholder="Email" ref="username" require/>
          <div class="invalid-feedback text-end"></div>
        </div>
        <div class="form-group" id="form-group-password">
          <label class="col-form-label">Password</label>
          <input class="form-control" v-model="password" type="password" require placeholder="Password" @keyup.enter="handleLogin()"/>
          <div class="invalid-feedback text-end"></div>
        </div>
        <div class="auth-form-action text-center">
          <button @click="handleLogin()" class="btn btn-primary btn-lg btn-fixed" type="button">Login</button>
          <p class="auth-form-info">No Account? <router-link to="/register" class="text-link d-inline">Register Here</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return{
      username: '',
      password: ''
    }
  },
  methods: {
    resetErrorMessage: function(id) {
      $(id).find('.form-control').removeClass('is-invalid is-valid')
         .end()
         .find('.invalid-feedback').text('');
    },
    setErrorMessage: function(id, message) {
      $(id).find('.form-control').addClass('is-invalid')
         .end()
         .find('.invalid-feedback').text(message);
    },
    validateEmail: function (email) {
      // http://emailregex.com/
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var re_payment = /^[0-9-a-zA-Z_\-\.@]+$/;
      return re.test(email) && re_payment.test(email);
    },
    handleLogin() {
      //reset
      this.resetErrorMessage('#login-form');
      var validate = true;

      //validate
      if(this.username === "") {
        validate = false;
        this.setErrorMessage('#form-group-username', "Please enter Email");
      } else {
        if (!this.validateEmail(this.username)) {
          validate = false;
          this.setErrorMessage('#form-group-username', "Pleaes confirm your email address");
        }
      }
      if(this.password === "") {
        validate = false;
        this.setErrorMessage('#form-group-password', "Please enter Password");
      }

      if(validate) {
        var self = this;
        this.axios.post(process.env.VUE_APP_API_URL+'/v1/login', {
          username: this.username,
          password: this.password
        }).then((response) => {
          if(response.data.code === 0) {
            //set cookies
            $cookies.set('aty_token', self.username, '7D');
            
            //check refer
            var refer = $cookies.get('aty_refer');
            if(refer === '/login' || refer === '/register') {
              refer = '/my_account/bio';
            }
            $cookies.remove('aty_refer');
            this.$router.push(refer);
          }
        }).catch(function (error) {
          self.setErrorMessage('#form-group-username', error.response.data.message);
        });
      }
    }
  }
}
</script>