<template>
  <Header />
    <div class="content-container container">
      <Carousel />
      <PageNav />
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4" v-for="row in list"><div class="artwork-card">
          <router-link :to="{path: '/artwork/'+row._id}">
            <img class="cover w-100" :src="getPhoto(row.thumbnail[0])">
          </router-link>
          <div class="artwork-card__content">
            <router-link :to="{path: '/artwork/'+row._id}">
              <p class="title" v-text="row.title"></p>
            </router-link>
            <div class="clearfix">
              <div class="float-start">
                <p class="fw-bold" v-text="row.created_year"></p>
                <p class="fw-bold" v-text="row.artist"></p>
                <p v-text="row.material"></p>
                <p v-text="row.size"></p>
              </div>
              <div v-if="row.isNft.hasNft" class="link-box float-end">
                <img v-if="row.nft[0].blockchain == 'ethereum'" src="@/assets/image/eth-icon.svg">
                <img v-else src="@/assets/image/polygon-icon.svg">
              </div>
            </div>
          </div>
        </div></div>
      </div>
    </div>
  <Footer />
</template>

<script>

import Header from '@/components/Header.vue'
import Carousel from '@/components/Carousel.vue'
import PageNav from '@/components/PageNav.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Carousel,
    PageNav,
    Footer
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.axios.get(process.env.VUE_APP_API_URL+'/v1/product').then((response) => {
      this.list = response.data.data;
    }).catch(function (error) {
      console.log(error);
    });  
  },
  methods: {
    getPhoto: function(url) {
      return process.env.VUE_APP_SITE_URL + url;
    }
  }
}
</script>