<template>
  <div class="member-header-container clearfix">
    <div class="member-header-content">
      <input type="file" class="d-none" id="upload-avatar-file" ref="avatar_file" accept=".jpg, .png, .gif" @change="onChangeFileUpload('avatar')"/>
      <input type="file" class="d-none" id="upload-cover-file" ref="cover_file" accept=".jpg, .png, .gif" @change="onChangeFileUpload('cover')"/>
      <div v-if="tmp_cover_file" class="cover-block">
        <img class="cover" :src="preview_cover_url">
        <label v-if="edit" class="btn-icon edit-btn" for="upload-cover-file"><img src="@/assets/image/edit-icon.svg"></label>
        <p class="upload-error-message"></p>
      </div>
      <div v-else class="cover-block">
        <img v-if="profile.cover" class="cover" :src="profile.cover">
        <div v-else class="cover default">
          <img class="icon" src="@/assets/image/upload-cover-icon.svg">
          <p>Maximum Display size 400px wide by 1000px high.</p>
        </div>
        <label v-if="edit" class="btn-icon edit-btn" for="upload-cover-file"><img src="@/assets/image/edit-icon.svg"></label>
        <p class="upload-error-message"></p>
      </div>
      <div class="intro-block">
        <div v-if="tmp_avatar_file" class="avatar-block">
          <img class="avatar" :src="preview_avatar_url">
          <label v-if="edit" class="btn-icon edit-btn" for="upload-avatar-file"><img src="@/assets/image/edit-icon.svg"></label>
        </div>
        <div v-else class="avatar-block">
          <img v-if="profile.avatar" class="avatar" :src="profile.avatar">
          <div v-else class="avatar default">
            <img src="@/assets/image/upload-avatar-icon.svg">
          </div>
          <label v-if="edit" class="btn-icon edit-btn" for="upload-avatar-file"><img src="@/assets/image/edit-icon.svg"></label>
        </div>
        <p class="name" v-text="profile.name"></p>
        <p v-text="profile.category"></p>
      </div>
    </div>
    <a href="#" class="logout-link" @click.prevent="logout">Logout</a>
    <p class="my-account-tag">My account</p>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isAuthenticated: false,
      file_type_list: [
        "image/jpeg",
        "image/png",
        "image/gif"
      ],
      tmp_avatar_file: "",
      tmp_cover_file: "",
      preview_avatar_url: "",
      preview_cover_url: ""
    }
  },
  props: {
    profile: Object,
    edit: Boolean
  },
  mounted() {
    this.isAuthenticated = $cookies.get('aty_token') ? true : false;
  },
  methods: {
    onChangeFileUpload: function(type) {
      var file = (type === 'avatar') ? this.$refs.avatar_file.files[0] : this.$refs.cover_file.files[0]; ;

      //reset
      var validate = true;
      $('.upload-error-message').text('');

      //check file_max_size
      if(file.size >= 5000000) { //5MB
        validate = false;
        $('.upload-error-message').text(type+": File size exceeded 5MB.");
      }
      //check file_type
      if (this.file_type_list.indexOf(file.type) === -1) {
        validate = false;
        $('.upload-error-message').text(type+": File type is curerntly not supported, please contact us for support.");
      }

      if(validate) {
        var preview_url = URL.createObjectURL(file);
        if(type === 'avatar') {
          this.tmp_avatar_file = file;
          this.preview_avatar_url = preview_url;
        } else {
          this.tmp_cover_file = file;
          this.preview_cover_url = preview_url;
        }
      }
    },
    logout() {
      $cookies.remove('aty_token');
      this.isAuthenticated = false;
      this.$router.push('/');
    }
  }
}
</script>