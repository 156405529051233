<template>
  <nav class="navbar fixed-top">
    <div class="container-fluid navbar-inner">
      <router-link to="/" class="navbar-brand logo">
        <img class="logo" src="@/assets/image/logo.svg">
      </router-link>
      <button class="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-mobile-menu" aria-controls="navbar-mobile-menu" aria-expanded="false" @click="toggleMobileMenu()">
        <span class="navbar-toggler-icon">
          <i class="fas fa-bars navbar-toggler--is-close"></i>
          <i class="fas fa-times navbar-toggler--is-open"></i>
        </span>
      </button>
      <ul class="navbar-nav flex-row flex-wrap bd-navbar-nav navbar-desktop-menu">
        <li class="nav-item">
          <router-link to="/" class="nav-link">Artwork</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/artists" class="nav-link">Artist</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/gallery" class="nav-link">Art Gallery</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/nft" class="nav-link">NFT</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/event" class="nav-link">Event</router-link>
        </li>
      </ul>
      <ul v-if="isAuthenticated" class="navbar-nav flex-row flex-wrap ms-md-auto user-nav  navbar-desktop-menu">
        <li class="nav-item">
          <router-link to="/my_account/bio" class="nav-link">My account</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/my_artworks/original" class="nav-link">Add an artwork</router-link>
        </li>
        <!--
        <li class="nav-item member-avatar">
          <router-link to="/my_account/bio"><img src="@/assets/image/upload-avatar-icon.svg"></router-link>
        </li>
        -->
      </ul>
      <ul v-else class="navbar-nav flex-row flex-wrap ms-md-auto user-nav  navbar-desktop-menu">
        <li class="nav-item">
          <router-link to="/login" class="nav-link">Sign in</router-link>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse clearfix navbar-mobile-menu" id="navbar-mobile-menu">
      <ul>
        <li class="nav-item">
          <router-link to="/" class="nav-link">Artwork</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/artists" class="nav-link">Artist</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/gallery" class="nav-link">Art Gallery</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/nft" class="nav-link">NFT</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/event" class="nav-link">Event</router-link>
        </li>
        <li v-if="isAuthenticated" class="nav-item">
          <router-link to="/my_account/bio" class="nav-link">My account</router-link>
        </li>
        <li v-if="isAuthenticated" class="nav-item">
          <router-link to="/my_artworks/original" class="nav-link">Add an artwork</router-link>
        </li>
      </ul>
      <div class="member-links-container">
        <a v-if="isAuthenticated" href="#" class="btn btn-lg btn-primary" @click.prevent="logout">Logout</a>
        <router-link to="/login" v-else class="btn btn-lg btn-primary">Sign in</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isAuthenticated: false
    }
  },
  mounted() {
    this.isAuthenticated = $cookies.get('aty_token') ? true : false;
  },
  methods: {
    toggleMobileMenu() {
      var stat = $('.navbar-toggler').hasClass('--is-active');
      if(stat) {
        $('.navbar-toggler').removeClass('--is-active');
      } else {
        $('.navbar-toggler').addClass('--is-active');
      }
    },
    logout() {
      $cookies.remove('aty_token');
      this.isAuthenticated = false;
      this.$router.push('/');
    }
  }
}
</script>