<template>
	<div class="carousel-container"><div id="main-carousel" class="carousel slide" data-bs-ride="carousel">
	    <div class="carousel-indicators">
	      <button type="button" data-bs-target="#main-carousel" data-bs-slide-to="0" class="active" aria-current="true"></button>
	      <button type="button" data-bs-target="#main-carousel" data-bs-slide-to="1"></button>
	      <button type="button" data-bs-target="#main-carousel" data-bs-slide-to="2"></button>
	      <button type="button" data-bs-target="#main-carousel" data-bs-slide-to="3"></button>
	      <button type="button" data-bs-target="#main-carousel" data-bs-slide-to="4"></button>
	    </div>
	    <div class="carousel-inner">
	      <div class="carousel-item active"><img src="@/assets/image/carousel-0.jpg" class="d-block w-100"></div>
	      <div class="carousel-item"><img src="@/assets/image/carousel-1.jpg" class="d-block w-100"></div>
	      <div class="carousel-item"><img src="@/assets/image/carousel-2.jpg" class="d-block w-100"></div>
	      <div class="carousel-item"><img src="@/assets/image/carousel-3.jpg" class="d-block w-100"></div>
	      <div class="carousel-item"><img src="@/assets/image/carousel-5.jpg" class="d-block w-100"></div>
	    </div>
  </div></div>
</template>