<template>
  <Header />
    <div class="content-container container">
      <Carousel />
      <PageNav />
      <div class="event-card"><div class="row">
        <img class="col-12 col-md-7 cover" src="@/assets/image/event/event-1.jpg">
        <div class="col-12 col-md-5"><div class="event-card__content">
          <p class="title">Winter Art Fair</p>
          <p class="date">2021/12/21</p>
          <p class="intro">
            There will be more new generation artists participating in this exhibition, so stay tuned...
          </p>
        </div></div>
      </div></div>
      <div class="event-card"><div class="row">
        <img class="col-12 col-md-7 cover" src="@/assets/image/event/event-2.jpg">
        <div class="col-12 col-md-5"><div class="event-card__content">
          <p class="title">New Art License Exhibition</p>
          <p class="date">2022/01/17</p>
          <p class="intro">
            There will be more new generation artists participating in this exhibition, so stay tuned...
          </p>
        </div></div>
      </div></div>
      <div class="event-card"><div class="row">
        <img class="col-12 col-md-7 cover" src="@/assets/image/event/event-3.jpg">
        <div class="col-12 col-md-5"><div class="event-card__content">
          <p class="title">Tom Rowland<br>My 72 shades of blue</p>
          <p class="date">2022/03/01</p>
          <p class="intro">
            On the way of creation, blue tone is aninseparable and important element...
          </p>
        </div></div>
      </div></div>
      <div class="event-card"><div class="row">
        <img class="col-12 col-md-7 cover" src="@/assets/image/event/event-4.jpg">
        <div class="col-12 col-md-5"><div class="event-card__content">
          <p class="title">Micro Device Interactive Exhibition</p>
          <p class="date">2022/03/16</p>
          <p class="intro">
            There will be more new generation artists participating in this exhibition, so stay tuned...
          </p>
        </div></div>
      </div></div>
      <div class="event-card"><div class="row">
        <img class="col-12 col-md-7 cover" src="@/assets/image/event/event-5.jpg">
        <div class="col-12 col-md-5"><div class="event-card__content">
          <p class="title">Teddy Sen My impression age</p>
          <p class="date">2022/04/02</p>
          <p class="intro">
            Impressionism focuses on depicting the sudden scene of nature, making a moment eternal, and applying this scientific principle to painting. Impressionist observation...
          </p>
        </div></div>
      </div></div>  
      <div class="event-card"><div class="row">
        <img class="col-12 col-md-7 cover" src="@/assets/image/event/event-6.jpg">
        <div class="col-12 col-md-5"><div class="event-card__content">
          <p class="title">When technology meets art</p>
          <p class="date">2022/04/18</p>
          <p class="intro">
            Art is everywhere, depending on how we think of it as art. Art is never just a skill, but a part of life. In the process of artistic creation, we learn how to think creatively, how to solve problems, how to cooperate with others...
          </p>
        </div></div>
      </div></div> 
      <div class="event-card"><div class="row">
        <img class="col-12 col-md-7 cover" src="@/assets/image/event/event-7.jpg">
        <div class="col-12 col-md-5"><div class="event-card__content">
          <p class="title">Light universe</p>
          <p class="date">2022/05/08</p>
          <p class="intro">
            There will be more new generation artists participating in this exhibition, so stay tuned...
          </p>
        </div></div>
      </div></div> 
    </div>
  <Footer />
</template>

<script>

import Header from '@/components/Header.vue'
import Carousel from '@/components/Carousel.vue'
import PageNav from '@/components/PageNav.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Carousel,
    PageNav,
    Footer
  }
}
</script>