<template>
	<div class="subnav-block">
	    <p class="title">Artify your art collections</p>
	    <ul class="">
	      <li class=""><router-link to="/" class="nav-link">Artwork</router-link></li>
	      <li class=""><router-link to="/artists" class="nav-link">Artist</router-link></li>
	      <li class=""><router-link to="/gallery" class="nav-link">Art Gallery</router-link></li>
	      <li class=""><router-link to="/nft" class="nav-link">NFT</router-link></li>
	      <li class=""><router-link to="/event" class="nav-link">Event</router-link></li>
	    </ul>
  	</div>
</template>