import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueCookies from "vue-cookies";
import axios from 'axios'
import VueAxios from 'vue-axios'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'popper.js'
import 'bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@/assets/scss/style.scss'

const app = createApp(App);

axios.defaults.withCredentials = true;

app.use(VueAxios, axios);
app.use(router);
router.app = app;

app.config.globalProperties.$cookies = VueCookies;
app.config.globalProperties.$router = router;

app.mount("#app");
