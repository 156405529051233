<template>
  <Header />
    <div class="content-container container">
      <Carousel />
      <PageNav />
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-Song-of-Spring-Art-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">Song of Spring Art Gallery</p>
            <p>Asia</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-XIII-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">XIII Gallery</p>
            <p>Africa</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-Moher-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">Moher Gallery</p>
            <p>Europe</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-Nikko-Art-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">Nikko Art Gallery</p>
            <p>Asia</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-TT-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">TT Gallery</p>
            <p>Barcelona</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-XII-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">XII Gallery</p>
            <p>North America</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-DX-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">DX Gallery</p>
            <p>North America</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-Maple-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">Maple Gallery</p>
            <p>Asia</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-Mei-Lanfang-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">Mei Lanfang Gallery</p>
            <p>Asia</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-VD-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">VD Gallery</p>
            <p>South America</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-Dawn-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">Dawn Gallery</p>
            <p>Oceania</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-Gezhi-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">Gezhi Gallery</p>
            <p>Asia</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-GKC-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">GKC Gallery</p>
            <p>Oceania</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-Hill-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">Hill Gallery</p>
            <p>Europe</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="gallery-card">
          <img class="cover w-100" src="@/assets/image/gallery/cover-One-Gallery.jpg">
          <div class="gallery-card__content">
            <p class="title">One Gallery</p>
            <p>North America</p>
          </div>
        </div></div>
      </div>      
    </div>
  <Footer />
</template>

<script>

import Header from '@/components/Header.vue'
import Carousel from '@/components/Carousel.vue'
import PageNav from '@/components/PageNav.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Carousel,
    PageNav,
    Footer
  }
}
</script>