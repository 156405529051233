<template>
<div class="modal" id="message-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered message-modal" role="document">
    <div class="modal-content">
      <div class="modal-body"><div class="modal-content-block">
        <div v-if="message.type == 'loading'" class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <h3 v-text="message.title"></h3>
        <p v-text="message.info"></p>
        <div v-if="message.type !== 'loading'" class="modal-action">
          <a v-if="message.action.url" :href="message.action.url" class="btn btn-primary" v-text="message.action.title"></a>
          <button v-else type="button" class="btn btn-primary" @click="close()">Close</button>
        </div>
      </div></div>
    </div>
  </div>
</div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
  data() {
    return {
      message: {
        'type': '',
        'title': '',
        'info': '',
        'action': {}
      }
    }
  },
  mounted: function () {
    this.modalel = new Modal(document.getElementById('message-modal'), {
      keyboard: false
    });

    var modalel = document.getElementById('message-modal');
    modalel.addEventListener('show.bs.modal', function (event) {
      $('.modal-backdrop').addClass('modal-backdrop--message');
    });
    modalel.addEventListener('hide.bs.modal', function (event) {
      $('.modal-backdrop').removeClass('modal-backdrop--message');
    }); 
  },
  methods: {
    loading: function(title = '', info = '', action = {}) {
      //update
      if(title == '') {
        title = 'Please Wait...';
      }

      this.message = {
        'type': 'loading',
        'title': title,
        'info': info,
        'action': action
      }
      this.show();
    },
    success: function(title = '', info = '', action = {}) {
      if(title == '') {
        title = 'Success!';
      }

      this.message = {
        'type': 'success',
        'title': title,
        'info': info,
        'action': action
      }
      this.show();
    },
    error: function(title = '', info = '', action = {}) {
      if(title == '') {
        title = 'Fail!';
      }

      this.message = {
        'type': 'error',
        'title': title,
        'info': info,
        'action': action
      }
      this.show();
    },
    upgrade: function(title = '', info = '', action = {}) {
      if(title == '') {
        title = 'Upgrade!';
      }

      this.message = {
        'type': 'upgrade',
        'title': title,
        'info': info,
        'action': action
      }
      this.show();
    },
    show: function() {
      if(!$('#message-modal').hasClass('show')) {
        this.modalel.show();
      }
    },
    close: function() {
      //reset
      this.message = {
        'type': '',
        'title': '',
        'info': '',
        'action': {}
      }
      this.modalel.hide();
    }
  }
}
</script>
