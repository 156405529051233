<template>
  <Header />
    <MessageModal  ref="message"/>
    <div class="content-container">
      <div class="container">
        <!-- stepper -->
        <div v-if="type === 'nft'" class="mint-info-container">
          <img class="artwork-thumbnail" :src="preview_file_url">
          <p>Your NFT will be minted on Polygon <img class="icon" src="@/assets/image/polygon-icon.svg"></p>
        </div>
        <div class="stepper-wrapper"><div class="stepper-block">
          <div v-if="type === 'artwork'" v-for="(title, index) in artwork_steps" class="stepper-item" :class="{ 'completed' : (index+1) < step, 'active' : (index+1) == step}">
            <span class="step-counter"></span>
            <p class="step-name" v-text="title"></p>
          </div>
          <div v-else v-for="(title, index) in nft_steps" class="stepper-item" :class="{ 'completed' : (index+3) < step, 'active' : (index+3) == step}">
            <span class="step-counter"></span>
            <p class="step-name" v-text="title"></p>
          </div>
        </div></div>
        <div class="row"><div class="col-12 col-md-8 offset-md-2">
          <!-- step1 -->
          <div v-if="step === 1" class="form-container create-artwork-container" id="artwork-form">
            <div class="form-group col-12" id="form-group-file">
              <label class="col-form-label">Image of Original Artwork<span class="text-danger">*</span></label>
              <input type="file" class="form-control d-none" id="upload-file" ref="file" accept=".jpg, .png, .gif" @change="onChangeFileUpload()"/>
              <div v-if="preview_file_url" class="upload-thumbnail-block">
                <img class="w-100" :src="preview_file_url">
                <label class="btn btn-icon reupload-file-btn" for="upload-file"><img src="@/assets/image/upload-file-icon.svg"></label>
              </div>
              <div v-else class="upload-thumbnail-block default">
                <p class="file-intro">JPG / PNG / GIF Max 15MB</p>
                <label class="btn btn-primary upload-file-btn" for="upload-file">Choose file</label>
              </div>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group col-12" id="form-group-title">
              <label class="col-form-label">Artwork title<span class="text-danger">*</span></label>
              <input class="form-control" type="text" v-model="artwork.title" placeholder="Please enter the title of this artwork.">
              <p class="form-text form-title-text">You will not be able to change this field once the original artwork has been added on Artify.</p>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group col-12" id="form-group-artist">
              <label class="col-form-label">Artist name<span class="text-danger">*</span></label>
              <input class="form-control" type="text" v-model="artwork.artist" placeholder="Name of the creater.">
              <p class="form-text form-title-text">You will not be able to change this field once the original artwork has been added on Artify.</p>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group col-12" id="form-group-year">
              <label class="col-form-label">Year created<span class="text-danger">*</span></label>
              <input class="form-control" type="text" v-model="artwork.created_year" placeholder="The year this orginal artwork was created. ie. 2021.">
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group col-12" id="form-group-material">
              <label class="col-form-label">Art type<span class="text-danger">*</span></label>
              <textarea class="form-control" rows="10" required v-model="artwork.material" placeholder="Briefly describe the type or method of creation used in this piece of original artwork. Try:Free creation Acrylic paint Painted on canvas or Digital art 3D render"></textarea>
              <p class="form-text text-end" v-text="countChar(artwork.material, 80,  'material')"></p>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group col-12" id="form-group-size">
              <label class="col-form-label">Size<span class="text-danger">*</span></label>
              <input class="form-control" type="text" v-model="artwork.size" placeholder="Size of this original artwork. Try 77 x 77 cm | 30 x 30 inches or 2200px x 3600px">
              <p class="form-text text-end" v-text="countChar(artwork.size, 50,  'size')"></p>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group col-12" id="form-group-description">
              <label class="col-form-label">Description<span class="text-danger">*</span></label>
              <textarea class="form-control" rows="10" required v-model="artwork.description" placeholder="Briefly describe this piece of original artwork. Try: “My Heart Is All a Flutter” by Luara Davis is a 3D Metal Wall Sculpture. or “Mandela” by Kobra Kan is a Original Acrylic on Canvas."></textarea>
              <p class="form-text text-end" v-text="countChar(artwork.description, 250,  'description')"></p>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group form-submit clearfix">
              <router-link to="/my_artworks/list" class="btn btn-outline-primary float-start">Cancel</router-link>
              <button class="btn btn-primary float-end" @click="createArtwork()">Next</button>
            </div>
          </div>
          <!-- step2 -->
          <div v-if="step === 2" class="preview-artwork-container">
            <p class="info">This is how your original artwork will be displayed on Artify</p>
            <div class="col-12 col-md-8 offset-md-2"><div class="artwork-card preview">
              <img class="w-100" :src="preview_file_url">
              <div class="artwork-card__content">
                <p class="title" v-text="artwork.title"></p>
                <p class="fw-bold">{{artwork.created_year}} / {{artwork.artist}}</p>
                <p v-text="artwork.material"></p>
                <p v-text="artwork.size"></p>
              </div>
            </div></div>
            <p class="info">How would you like to sell this art piece?</p>
            <button class="d-block w-100 btn btn-primary disabled">The physical piece of this artwork</button>
            <button class="d-block w-100 btn btn-primary" @click="goto(3)">Original NFT of this artwork</button>
            <button class="d-block w-100 btn btn-primary disabled">IP rights to this artwork</button>
              <button class="btn btn-outline-primary" @click="goto(1)">Back</button>
          </div>
          <!-- (nft) step1 -->
          <div v-if="step === 3" class="form-container create-artwork-container" id="wallet-address-form">
            <div class="form-group col-12" id="form-group-address">
              <label class="col-form-label w-100">
                Enter your wallet address<span class="text-danger">*</span>
                <p class="float-end">What is a wallet?</p>
              </label>
              <input class="form-control" type="text" v-model="artwork.wallet_address" placeholder="Cut and paste your wallet address here">
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group form-submit clearfix">
              <router-link to="/my_artworks/list" class="btn btn-outline-primary float-start">Cancel</router-link>
              <button class="btn btn-primary float-end" @click="addWalletAddress()">Next</button>
              <button class="btn btn-outline-primary float-end me-3" @click="goto(2)">Back</button>
            </div>
            <div class="wallet-address-info">
              <p class="title">Tip:</p>
              <p>The wallet address can be found in your crypto wallet, simply copy and paste to the wallet address field above</p>
              <img src="@/assets/image/printscreen-wallet-address.png">
            </div>
          </div>
          <!-- (nft) step2 -->
          <div v-if="step === 4" class="form-container create-artwork-container" id="mint-nft-form">
            <div class="form-group col-12" id="form-group-title">
              <label class="col-form-label">Artwork title<span class="text-danger">*</span></label>
              <input class="form-control" type="text" v-model="artwork.title" placeholder="Please enter the title of this artwork." disabled>
              <p class="form-text form-title-text">You have previously established the artwork title, therefore it cannot be changed here.</p>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group col-12" id="form-group-name">
              <label class="col-form-label">Artist name<span class="text-danger">*</span></label>
              <input class="form-control" type="text" v-model="artwork.artist" placeholder="Name of the creater." disabled>
              <p class="form-text form-title-text">You have previously established the artwork title, therefore it cannot be changed here.</p>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group col-12" id="form-group-nft-description">
              <label class="col-form-label">Description<span class="text-danger">*</span></label>
              <textarea class="form-control" rows="10" required v-model="artwork.nft_description" placeholder="Briefly describe this piece of original artwork. Try: 1 of 1 NFT collectible edition of “My Heart Is All a Flutter” a 3D Metal Wall Sculpture by Luara Davis.or Original NFT collectible of “Mandela” an Original Acrylic on Canvas by Kobra Kan."></textarea>
              <p class="form-text form-title-text">You will not be able to change this field once the NFT Smart Contract has been created on the blockchain.</p>
              <p class="form-text text-end" v-text="countChar(artwork.nft_description, 250, 'nft-description')"></p>
              <p class="invalid-feedback text-end"></p>
            </div>
            <div class="form-group form-submit clearfix">
              <router-link to="/my_artworks/list" class="btn btn-outline-primary float-start">Cancel</router-link>
              <button class="btn btn-primary float-end create-nft-btn" @click="confirmNFTData()">Next</button>
              <button class="btn btn-outline-primary float-end me-3" @click="goto(3)">Back</button>
            </div>
          </div>
          <!-- (nft) step3 -->
          <div class="payment-info-container d-none" id="tappay-container">
            <div class="payment-info-box">
              <p class="box-title">Order summary</p>
              <p class="title">NFT artwork title</p>
              <p v-text="artwork.nft_title"></p>
              <p class="title">Artist name</p>
              <p v-text="artwork.nft_artist"></p>
              <p class="title">Description</p>
              <p v-text="artwork.nft_description"></p>
            </div>
            <div class="payment-info-box">
              <p class="box-title">NFT minting fee</p>
              <p>Let us take care of the calculation, so you don't have to. Minting fee includes: gas price, gwei, gas limit, and handling fee.</p>
              <div class="price-box">
                <p>Total: USD ${{ billing.price }}</p>
              </div>
            </div>
            <div class="form-container" id="confirm-form">
              <div class="checkbox checkbox-group" id="form-group-confirm">
                <label class="form-control">
                  <input type="checkbox" v-model="billing.nft_terms">
                  I understand that the price of Artify NFT Minting service is subject to change without notice.
                </label>
                <div class="invalid-feedback text-end"></div>
              </div>
              <div class="checkbox checkbox-group" id="form-group-terms">
                <label class="form-control">
                  <input type="checkbox" v-model="billing.artify_terms">
                  I agree to Artify Terms of Service
                </label>
                <div class="invalid-feedback text-end"></div>
              </div>
            </div>
            <!-- card -->
            <div class="payment-info-box">
              <p class="block-title">Payment Details</p>
            </div>
            <div class="form-container billing-form" id="billing-form">
              <div class="row">
                <div class="form-group col-md-12" id="form-group-name">
                  <label class="col-form-label">Card holder <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="Name on your credit card" v-model="billing.name">
                  <div class="invalid-feedback text-end"></div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12 col-md-6" id="form-group-number">
                  <label class="col-form-label w-100">
                    Credit card number <span class="text-danger">*</span>
                    <img class="float-end cards-icon" src="@/assets/image/cards-icon.png">
                  </label>
                  <div class="form-control billing" id="card-number"></div>
                  <div class="invalid-feedback text-end"></div>
                </div>
                <div class="form-group col-12 col-md-3" id="form-group-expired-date">
                  <label class="col-form-label">Expiration date <span class="text-danger">*</span></label>
                  <div class="form-control billing" id="card-expiration-date"></div>
                  <div class="invalid-feedback text-end"></div>
                </div>
                <div class="form-group col-12 col-md-3" id="form-group-ccv">
                  <label class="col-form-label">Security code <span class="text-danger">*</span></label>
                  <div class="form-control billing" id="card-ccv"></div>
                  <div class="invalid-feedback text-end"></div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12 col-md-9" id="form-group-country">
                  <label class="col-form-label">Country <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="Card issuing country" v-model="billing.country" id="country">
                  <div class="invalid-feedback text-end"></div>
                </div>
                <div class="form-group col-12 col-md-3" id="form-group-zip">
                  <label class="col-form-label">Zip/Postal code <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="Card zip/post code" v-model="billing.zip">
                  <div class="invalid-feedback text-end"></div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12 col-md-9" id="form-group-address">
                  <label class="col-form-label">Billing address <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="Address where the card is registered at" v-model="billing.address" id="address">
                  <div class="invalid-feedback text-end"></div>
                </div>
                <div class="form-group col-12 col-md-3" id="form-group-mobile">
                  <label class="col-form-label">Contact number <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="Landline or mobile" v-model="billing.mobile">
                  <div class="invalid-feedback text-end"></div>
                </div>
              </div>
              <div class="checkbox checkbox-group" id="form-group-exchange">
                <label class="form-control form-control__no-border">
                  <input type="checkbox" v-model="billing.exchange_terms">
                  I understand and agree that the above credit card will be charged in NTD. Foreign exchange rate may be applied. Your bank may charge you additional fees. For details of these fees, please contact your bank directly. Artify’s foreign exchange rate: USD $1 = NTD $30
                </label>
                <div class="invalid-feedback text-end"></div>
              </div>
              <div class="form-group form-submit clearfix">
                <router-link to="/my_artworks/list" class="btn btn-outline-primary float-start">Cancel</router-link>
                <button class="btn btn-primary btn-fixed float-end checkout-btn" @click="payment()">Pay USD ${{ billing.price }} Now</button>
              </div>
            </div>
          </div>
          <div v-if="step === 6" class="payment-info-container">
           <div v-if="payment_status === 'success'">
              <div class="payment-status-box">
                <img src="@/assets/image/payment-success.svg">
                <p class="title">Payment complete!</p>
                <p class="title info">Thank you for supporting Artify</p>
                <p class="message">The relevant payment details have been sent to your email.</p>
              </div>
              <div class="manage-artwork-box clearfix">
                <p class="float-start">
                  <img src="@/assets/image/polygon-icon-white.svg">
                  Minting complete <i class="fas fa-check ms-3"></i>
                </p>
                <router-link :to="{path: '/artwork_detail/'+product_id}" class="btn btn-white float-end">See artwork details</router-link>
              </div>
              <div class="payment-info-box">
                <p class="box-title">Order summary</p>
              </div>
              <div class="payment-info-box">
                <p class="title">Payment Details</p>
                <div class="price-box">
                  <p>Total: USD ${{ billing.price }}</p>
                </div>
              </div>
              <div class="payment-info-box">
                <p class="title">NFT artwork title</p>
                <p v-text="artwork.nft_title"></p>
                <p class="title">Artist name</p>
                <p v-text="artwork.nft_artist"></p>
                <p class="title">Description</p>
                <p v-text="artwork.nft_description"></p>
              </div>
              <div class="clearfix">
                <router-link to="/my_artworks/list" class="btn btn-outline-primary float-end">Finish</router-link>
              </div>
           </div>
           <div v-else class="payment-status-box clearfix">
              <img src="@/assets/image/payment-fail.svg">
              <p class="title">Payment Failed</p>
              <p class="title info">Please try again</p>

              <router-link to="/my_artworks/list" class="btn btn-outline-primary float-start">Cancel</router-link>
              <button class="btn btn-primary float-end" @click="goto(5)">Back</button>
            </div>
          </div>
        </div></div>
      </div>
    </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import MessageModal from '@/components/MessageModal.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer,
    MessageModal
  },
  data() {
    return{
      type: "artwork", //artwork, nft
      step: 1, 
      artwork_steps: ['Original artwork', 'Selling method'],
      nft_steps: ['Wallet address', 'NFT artwork details', 'Payment', 'Finish'],
      file_type_list: [
        "image/jpeg",
        "image/png",
        "image/gif"
      ],
      tmp_file: "",
      preview_file_url: "",
      artwork: {
        thumbnail: [],
        title: "",
        artist: "",
        created_year: "",
        material: "",
        size: "",
        description: "",
        nft_description: "",
        nft_thumbnail: "",
        wallet_address: ""
      },
      product_id: "",
      nft_id: "",
      billing: {
        name: '',
        country: '',
        zip: '',
        address: '',
        mobile: '',
        nft_terms: false,
        artify_terms: false,
        exchange_terms: false,
        price: 1
      },
      payment_status: "" //success, fail
    }
  },
  mounted() {
    //country
    $("#country").countrySelect({
     defaultCountry: 'tw',
     responsiveDropdown: true
    });

    //tappay
    TPDirect.setupSDK(process.env.VUE_APP_TAPPAY_APP_ID, process.env.VUE_APP_TAPPAY_APP_KEY, process.env.VUE_APP_TAPPAY_ENV);
    
    TPDirect.card.setup({
      fields: {
        number: {
          element: "#card-number",
          placeholder: "XXXX XXXX XXXX XXXX"
        },
        expirationDate: {
          element: "#card-expiration-date",
          placeholder: "MM/YY"
        },
        ccv: {
          element: "#card-ccv",
          placeholder: "CVV/CVC"
        }
      },
      styles: {
        "input": {
          "color": "gray"
        },
        ":focus": {
          "color": "black"
        },
        ".valid": {
          "color": "green"
        },
        ".invalid": {
          "color": "red"
        }
      }
    });
    // listen for TapPay Field
    var self = this;
    TPDirect.card.onUpdate(function (update) {
      //reset
      let validate = true;
      $('.checkout-btn').attr('disabled', true);
      self.resetErrorMessage('#form-group-number');
      self.resetErrorMessage('#form-group-expired-date');
      self.resetErrorMessage('#form-group-ccv');

      //validate card number
      if (update.status.number === 2) {
        validate = false;
        self.setErrorMessage('#form-group-number','Your card number is incorrect')
      }
      //validate expired date
      if (update.status.expiry === 2) {
        validate = false;
        self.setErrorMessage('#form-group-expired-date','Your expired date is incorrect')
      }
      //validate ccv
      if (update.status.ccv === 2) {
        validate = false;
        self.setErrorMessage('#form-group-ccv','Your ccv is incorrect')
      }

      if (validate && update.canGetPrime) {
        //success
        $('.checkout-btn').removeAttr('disabled');
      }
    });
  },
  methods: {
    getPrime() {
      // fix keyboard issue in iOS device
      this.forceBlurIos();
      var self = this;
      // Get prime
      if(TPDirect.card.getTappayFieldsStatus().canGetPrime){
        TPDirect.card.getPrime(function (result) {
          if (result.status !== 0) {
            self.$refs.message.error('get prime error ' + result.msg);
            return;
          }
          self.checkout(result.card);
        })
      }
    },
    forceBlurIos() {
      if (!this.isIos()) {
         return
      }
      var input = document.createElement('input')
       input.setAttribute('type', 'text')
       // Insert to active element to ensure scroll lands somewhere relevant
       document.activeElement.prepend(input)
       input.focus()
       input.parentNode.removeChild(input)
    },
    isIos() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    countChar: function(string, max, tag) {
      var num = string.length;
      if(num > max) {
        // error message
        $('#form-group-'+tag).find('.form-control').addClass('is-invalid');
        $('#form-group-'+tag).find('.invalid-feedback').text(max+' character limit has been reached.');
      } else {
        // reset
        $('#form-group-'+tag).find('.form-control').removeClass('is-invalid is-valid');
      }
      return '('+ num +'/'+ max +')';
    },
    goto: function(step) {
      this.step = step;
      this.type = (step <= 2) ? 'artwork' : 'nft';
      window.scrollTo(0,0);

      if(this.step === 5) {
        $('#tappay-container').removeClass('d-none');
      } else {
        $('#tappay-container').addClass('d-none');
      }
    },
    resetErrorMessage: function(id) {
      $(id).find('.form-control').removeClass('is-invalid is-valid')
         .end()
         .find('.invalid-feedback').text('');
    },
    setErrorMessage: function(id, message) {
      $(id).find('.form-control').addClass('is-invalid')
         .end()
         .find('.invalid-feedback').text(message);
    },
    onChangeFileUpload: function(force = false) {
      //reset
      var validate = true;
      this.tmp_file = this.$refs.file.files[0]; 
      
      //check file_max_size
      if(this.tmp_file.size >= 15000000) { //15MB
        validate = false;
        this.setErrorMessage('#form-group-file', "File size exceeded 15MB.");
      }
      //check file_type
      if (this.file_type_list.indexOf(this.tmp_file.type) === -1) {
        validate = false;
        this.setErrorMessage('#form-group-file', "File type is curerntly not supported, please contact us for support.");
      }

      if(validate) {
        this.preview_file_url = URL.createObjectURL(this.tmp_file);
      } else {
        this.tmp_file = ''; 
      }
    },
    createArtwork: function() {
      //reset
      this.resetErrorMessage('#artwork-form');
      var validate = true;

      //validate
      if(this.tmp_file === "") {
        validate = false;
        this.setErrorMessage('#form-group-file', "Please upload file");
      }
      if(this.artwork.title === "") {
        validate = false;
        this.setErrorMessage('#form-group-title', "Please enter Artwork title");
      }
      if(this.artwork.artist === "") {
        validate = false;
        this.setErrorMessage('#form-group-artist', "Please enter Artist name");
      }
      if(this.artwork.created_year === "") {
        validate = false;
        this.setErrorMessage('#form-group-year', "Please enter Year created");
      }
      if(this.artwork.material === "") {
        validate = false;
        this.setErrorMessage('#form-group-material', "Please enter Art type");
      }
      if(this.artwork.size === "") {
        validate = false;
        this.setErrorMessage('#form-group-size', "Please enter Size");
      }
      if(this.artwork.description === "") {
        validate = false;
        this.setErrorMessage('#form-group-description', "Please enter Description");
      }
      
      if(validate) {
        this.goto(2);
      }
    },
    addWalletAddress: function() {
      //reset
      this.resetErrorMessage('#wallet-address-form');
      var validate = true;

      //validate
      if(this.artwork.wallet_address === "") {
        validate = false;
        this.setErrorMessage('#form-group-address', "Please enter Wallet address");
      }
      
      if(validate) {
        this.goto(4);
      }
    },
    uploadFile: function(file, fncSuccess, fncFail) {
      var formData = new FormData();
      formData.append('file', file);
      formData.append('upload_to_ipfs', true);
      formData.append('type', 'product');

      this.axios.post(process.env.VUE_APP_API_URL+'/v1/file', formData, {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then((response) => {
        fncSuccess(response.data);
      }).catch(function (error) {
        fncFail(error.data);
      });
    },
    addArtwork: function(fncSuccess, fncFail) {
      this.axios.post(process.env.VUE_APP_API_URL+'/v1/product', this.artwork).then((response) => {
        fncSuccess(response.data);
      }).catch(function (error) {
        fncFail(error.response);
      });
    },
    confirmNFTData: function() {
      //reset
      this.resetErrorMessage('#mint-nft-form');
      var validate = true;

      //validate
      if(this.artwork.nft_description === "") {
        validate = false;
        this.setErrorMessage('#form-group-nft-description', "Please enter Description");
      }
      
      if(validate) {
        //call api 
        this.artwork.nft_title = this.artwork.title;
        this.artwork.nft_artist = this.artwork.artist;
        
        var self = this;
        $('.create-nft-btn').attr('disabled', true);
        this.$refs.message.loading();

        //create artwork
        //upload file
        this.uploadFile(this.tmp_file, function(response){
          self.artwork.nft_thumbnail = response.data.cid;
          self.artwork.thumbnail.push(response.data.file_url);

          //create artwork
          self.addArtwork(function(artwork_response){
            self.$refs.message.close();
            self.nft_id = (artwork_response.data.isNft) ? artwork_response.data.isNft.nft_id : "";
            self.product_id = (artwork_response.data._id) ? artwork_response.data._id : "";
            self.goto(5);
          }, function(error){
            $('.create-nft-btn').attr('disabled', false);
            self.$refs.message.error(error.data.message);
          });
        }, function(error){
          self.$refs.message.error(error.data.message);
        });
      }
    },
    payment: function() {
      let validate = true;
      //reset
      this.resetErrorMessage('#confirm-form');
      this.resetErrorMessage('#billing-form');
      // you'll get an object including name and iso2. two different key and we only need the name
      this.billing.country = $("#country").countrySelect("getSelectedCountryData").name;

      //validate
      if(this.billing.nft_terms === false) {
        validate = false;
        this.setErrorMessage('#form-group-confirm', "Please confirm our terms");
      }
      if(this.billing.artify_terms === false) {
        validate = false;
        this.setErrorMessage('#form-group-terms', "Please confirm our terms");
      }
      if(this.billing.name === "") {
        validate = false;
        this.setErrorMessage('#form-group-name', "Please enter billing name");
      }
      if(this.billing.country === "") {
        validate = false;
        this.setErrorMessage('#form-group-country', "Please enter country");
      }
      if(this.billing.zip === "") {
        validate = false;
        this.setErrorMessage('#form-group-zip', "Please enter zip code");
      }
      if(this.billing.address === "") {
        validate = false;
        this.setErrorMessage('#form-group-address', "Please enter address");
      }
      if(this.billing.mobile === "") {
        validate = false;
        this.setErrorMessage('#form-group-mobile', "Please enter Contact number");
      }
      if(this.billing.exchange_terms === false) {
        validate = false;
        this.setErrorMessage('#form-group-exchange', "Please confirm our terms");
      }

      if(validate) {
        this.getPrime(); 
      }
    },
    checkout: async function(card){
      let body = {
        price: this.billing.price,
        currency: 'USD',
        details: 'issue_nft_artify',
        cardholder: {
            phone_number: this.billing.mobile,
            name: this.billing.name,
            email: 'user account',
            country: this.billing.country,
            zip_code: this.billing.zip,
            address: this.billing.address
        },
        prime: card
      }

      var self = this;
      this.$refs.message.loading();
      this.axios.post(process.env.VUE_APP_API_URL+'/v1/nft/'+this.nft_id+'/tappay', body).then((response) => {
        //success
        self.$refs.message.close();
        self.payment_status = 'success';
        self.goto(6);

      }).catch(function (error) {
        self.$refs.message.close();
        self.payment_status = 'fail';
        self.goto(6);
      });
    }
  }
}
</script>