<template>
  <div class="auth-page">
    <div class="auth-container bg"></div>
    <div class="auth-container">
      <div class="form-container login-container" id="register-form">
        <div class="auth-header">
          <router-link to="/"><img class="logo" src="@/assets/image/logo.svg"></router-link>
          <p>Artify your art collections</p>
        </div>
        <div class="form-group" id="form-group-username">
          <label class="col-form-label">Email</label>
          <input class="form-control" type="text" v-model="username" placeholder="Enter a working email here" ref="username" require/>
          <div class="invalid-feedback text-end"></div>
        </div>
        <div class="form-group" id="form-group-password">
          <label class="col-form-label">Password</label>
          <input class="form-control" v-model="password" type="password" require placeholder="8-12 characters in length"/>
          <div class="invalid-feedback text-end"></div>
        </div>
        <div class="form-group" id="form-group-confirm">
          <label class="col-form-label">Confirm password</label>
          <input class="form-control js-user-confirm" type="password" placeholder="Confirm Password" v-model="confirmPassword" @keyup.enter="handleRegister" autocomplete="new-password"/>
          <div class="invalid-feedback text-end"></div>
        </div>
        <div id="form-group-checkbox" class="checkbox checkbox-group">
          <label class="form-control"><input type="checkbox" v-model="checkbox">I agree to the <a href="#" class="text-link">Terms of Service</a> and <a href="#" class="text-link">Privacy Policy</a>.</label>
          <div class="invalid-feedback text-end"></div>
        </div>
        <div class="auth-form-action text-center">
          <button @click="handleRegister" class="btn btn-primary btn-lg btn-fixed" type="button">Register</button>
          <p class="auth-form-info">Already have an account? <router-link to="/login" class="text-link d-inline">Login Here</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return{
      username: '',
      password: '',
      confirmPassword: '',
      checkbox: false
    }
  },
  methods: {
    resetErrorMessage: function(id) {
      $(id).find('.form-control').removeClass('is-invalid is-valid')
         .end()
         .find('.invalid-feedback').text('');
    },
    setErrorMessage: function(id, message) {
      $(id).find('.form-control').addClass('is-invalid')
         .end()
         .find('.invalid-feedback').text(message);
    },
    validateEmail: function (email) {
      // http://emailregex.com/
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var re_payment = /^[0-9-a-zA-Z_\-\.@]+$/;
      return re.test(email) && re_payment.test(email);
    },
    handleRegister() {
      //reset
      this.resetErrorMessage('#register-form');
      var validate = true;
      //validate
      if(this.username === "") {
        validate = false;
        this.setErrorMessage('#form-group-username', "Please enter your email");
      } else {
        if (!this.validateEmail(this.username)) {
          validate = false;
          this.setErrorMessage('#form-group-username', "Pleaes confirm your email address");
        }
      }

      if(this.password === "" || this.confirmPassword === "") {
        validate = false;

        this.setErrorMessage('#form-group-password', "Please enter your password");
        this.setErrorMessage('#form-group-confirm', "Please confirm the password");
      } else {
        if (this.password !== this.confirmPassword) {
          validate = false;
          this.setErrorMessage('#form-group-confirm', "Please confirm the password");
        }
      }
      
      if (!this.checkbox) {
        validate = false;
        this.setErrorMessage('#form-group-checkbox', "Please indicate that you accept the Terms of Service and Privacy Policy");
      }

      if(validate) {
        var self = this;
        this.axios.post(process.env.VUE_APP_API_URL+'/v1/register', {
          username: this.username,
          password: this.password
        }).then((response) => {
          if(response.data.code === 0) {
            //set cookies
            $cookies.set('aty_token', self.username, '7D');
            $cookies.remove('aty_refer');
            this.$router.push('/my_account/bio');
          }
        }).catch(function (error) {
          self.setErrorMessage('#form-group-username', error.response.data.message);
        });
      }
    }
  }
}
</script>