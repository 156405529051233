<template>
  <Header />
    <MessageModal ref="message"/>
    <div class="content-container container">
      <MemberHeader :profile=profile :edit="true" ref="member_header" />
      <div class="">
        <ul class="member-links">
          <li><router-link to="/my_account/bio">My account</router-link></li>
          <li><router-link to="/my_artworks/list">My artwork</router-link></li>
        </ul>
        <div class="row"><div class="col-12 col-md-8 form-container offset-md-2"><div class="form-container" id="profile-form">
          <div class="form-group col-12" id="form-group-name">
            <label class="col-form-label">Display name<span class="text-danger">*</span></label>
            <input class="form-control" type="text" v-model="profile.name" placeholder="This name will be used as your artist name across Artify platform">
            <p class="invalid-feedback text-end"></p>
          </div>
          <div class="form-group col-12" id="form-group-location">
            <label class="col-form-label">Location<span class="text-danger">*</span></label>
            <select class="form-control custom-select" v-model="profile.location">
              <option value="">Which region are you from</option>
              <option v-for="row in location_list" :value="row" v-text="row"></option>
            </select>
            <p class="invalid-feedback text-end"></p>
          </div>
          <div class="form-group col-12" id="form-group-category">
            <label class="col-form-label">Art category<span class="text-danger">*</span></label>
            <select class="form-control custom-select" v-model="profile.category">
              <option value="">Choose a category best describe your art work</option>
              <option v-for="row in categories" :value="row" v-text="row"></option>
            </select>
            <p class="invalid-feedback text-end"></p>
          </div>
          <div class="form-group col-12" id="form-group-description">
            <label class="col-form-label">Description<span class="text-danger">*</span></label>
            <textarea class="form-control" rows="3" required v-model="profile.description" placeholder="Write a short bio about yourself"></textarea>
            <p class="form-text text-end" v-text="countChar(profile.description, 250,  'description')"></p>
            <p class="invalid-feedback text-end"></p>
          </div>
          <div class="form-group col-12" id="form-group-experience">
            <label class="col-form-label">Experience<span class="text-danger">*</span></label>
            <textarea class="form-control" rows="10" required v-model="profile.experience" placeholder="A brief description about your experiences.Try exhibitions your works have appeared in, galleries that your art is currently displayed or media mentions."></textarea>
            <p class="form-text text-end" v-text="countChar(profile.experience, 1000,  'experience')"></p>
            <p class="invalid-feedback text-end"></p>
          </div>
          <div class="form-group form-submit text-end">
            <button class="btn btn-primary edit-profile-btn" @click="save()">Save</button>
          </div>
        </div></div></div>
      </div>
    </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import MessageModal from '@/components/MessageModal.vue'
import MemberHeader from '@/components/MemberHeader.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    MemberHeader,
    MessageModal,
    Footer
  },
  data() {
    return{
      profile: {
        name: "",
        location: "",
        category: "",
        description: "",
        experience: "",
        avatar: "",
        cover: ""
      },
      categories: [
        "Pencils",
        "Watercolor painter",
        "Ink painter",
        "Oil paint",
        "Pastel painter",
        "Sculptor",
        "Illustrator",
        "Photographer",
        "Digital art",
        "3D",
        "Other"
      ],
      location_list: [ 
        "Asia",
        "Africa",
        "Europe",
        "North America",
        "South America",
        "Oceania",
        "Other"
      ],
      step: 1
    }
  },
  mounted() {
    //get profile
    this.axios.get(process.env.VUE_APP_API_URL+'/v1/profile').then((response) => {
      if(response.data.code === 0 && Object.keys(response.data.data).length) {
        this.profile = response.data.data;
      }
    }).catch(function (error) {
      console.log(error);
    });
  },
  methods: {
    countChar: function(string, max, tag) {
      var num = string.length;
      if(num > max) {
        // error message
        $('#form-group-'+tag).find('.form-control').addClass('is-invalid');
        $('#form-group-'+tag).find('.invalid-feedback').text(max+' character limit has been reached.');
      } else {
        // reset
        $('#form-group-'+tag).find('.form-control').removeClass('is-invalid is-valid');
      }
      return '('+ num +'/'+ max +')';
    },
    resetErrorMessage: function(id) {
      $(id).find('.form-control').removeClass('is-invalid is-valid')
         .end()
         .find('.invalid-feedback').text('');
    },
    setErrorMessage: function(id, message) {
      $(id).find('.form-control').addClass('is-invalid')
         .end()
         .find('.invalid-feedback').text(message);
    },
    uploadFile: function(file, type, fncSuccess, fncFail) {
      var formData = new FormData();
      formData.append('file', file);
      formData.append('upload_to_ipfs', false);
      formData.append('type', type);

      this.axios.post(process.env.VUE_APP_API_URL+'/v1/file', formData, {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then((response) => {
        fncSuccess(response.data);
      }).catch(function (error) {
        fncFail(error.data);
      });
    },
    updateProfile: function(profile) {
      var self = this;

      this.axios.post(process.env.VUE_APP_API_URL+'/v1/profile', profile).then((response) => {
        self.step--;
        if(self.step === 0) {
          self.$refs.message.success('Success!');
          $('.edit-profile-btn').attr('disabled', false);
        }

      }).catch(function (error) {
        console.log(error.data);
      });
    },
    save: function() {
      //reset
      this.resetErrorMessage('#profile-form');
      var validate = true;

      //validate
      if(this.profile.name === "") {
        validate = false;
        this.setErrorMessage('#form-group-name', "Please enter Display name");
      }
      if(this.profile.location === "") {
        validate = false;
        this.setErrorMessage('#form-group-location', "Please enter Location");
      }
      if(this.profile.category === "") {
        validate = false;
        this.setErrorMessage('#form-group-category', "Please enter Art category");
      }
      if(this.profile.description === "") {
        validate = false;
        this.setErrorMessage('#form-group-description', "Please enter Description");
      }
      if(this.profile.experience === "") {
        validate = false;
        this.setErrorMessage('#form-group-experience', "Please enter Experience");
      }

      if(validate) {
        var self = this;

        this.$refs.message.loading();
        $('.edit-profile-btn').attr('disabled', true);

        var tmp_avatar_file = this.$refs.member_header.tmp_avatar_file;
        var tmp_cover_file = this.$refs.member_header.tmp_cover_file;

        if(tmp_avatar_file) {
          this.step++;
          this.uploadFile(tmp_avatar_file, 'avatar', function(response){
            self.updateProfile({'avatar':response.data.file_url});
          }, function(error){
            console.log(error);
          });
        }
        if(tmp_cover_file) {
          this.step++;
          this.uploadFile(tmp_cover_file, 'cover', function(response){
            self.updateProfile({'cover':response.data.file_url});
          }, function(error){
            console.log(error);
          });
        }

        var update_profile = Object.assign({}, this.profile); // 淺拷貝
        delete update_profile.avatar;
        delete update_profile.cover;
        
        this.updateProfile(update_profile);
      }
    }
  }
}
</script>