<template>
  <Header />
  <div class="content-container container">
    <div class="back-container">
      <router-link to="/" class="back-btn"><img src="@/assets/image/back-icon.svg"></router-link>
    </div>
    <div class="row artwork-info-container">
      <div class="col-12 col-sm-12 col-md-8"><div class="thumbnail-block">
        <img v-if="artwork.product.thumbnail" class="w-100" :src="getPhoto(artwork.product.thumbnail[0])">
        <img v-else class="w-100" src="@/assets/image/default-artwork.png">
      </div></div>
      <div class="col-12 col-sm-12 col-md-4"><div class="intro-block">
        <p class="artwork-tag">Original artwork</p>
        <p class="artwork-title" v-text="artwork.product.created_year"></p>
        <p class="artwork-title" v-text="artwork.product.title"></p>
        <p class="artwork-info" v-text="artwork.product.material"></p>
        <p class="artwork-info" v-text="artwork.product.size"></p>
        <p class="artwork-info" v-text="artwork.product.description"></p>
        <p class="fw-bold">Artist: {{artwork.product.artist}}</p>

        <div v-if="artwork.product.isEntity.hasEntityArtwork">
          <button class="btn-outline-white w-100 artwork-entity-btn" data-bs-toggle="collapse" href="#collapse-entity-info" role="button" aria-expanded="false" aria-controls="collapse-entity-info">
            <img class="icon" src="@/assets/image/physical-icon.svg"> 
            Physical Piece
            <i class="float-end fas fa-chevron-down"></i>
          </button>
          <div class="collapse" id="collapse-entity-info"><div class="card card-body">
            <p class="fw-bold mb-2" v-text="artwork.entity_artwork.title"></p>
            <p class="fw-bold mb-2" v-text="artwork.entity_artwork.created_year"></p>
            <p class="fw-bold mb-2" v-text="artwork.entity_artwork.artist"></p>
            <p class="mb-2" v-text="artwork.entity_artwork.material"></p>
            <p class="mb-2" v-text="artwork.entity_artwork.size"></p>
            <p v-text="artwork.entity_artwork.description"></p>
          </div></div>
        </div>
        <div v-if="artwork.product.isNft.hasNft">
          <button v-if="artwork.nft.blockchain == 'ethereum'" 
            class="btn-primary w-100 artwork-nft-btn is-ethereum" data-bs-toggle="collapse" href="#collapse-nft-info" role="button" aria-expanded="false" aria-controls="collapse-nft-info">
            <img class="icon" src="@/assets/image/eth-icon-white.svg"> NFT
            <i class="float-end fas fa-chevron-down"></i>
          </button>
          <button v-else class="btn-primary w-100 artwork-nft-btn is-polygon" data-bs-toggle="collapse" href="#collapse-nft-info" role="button" aria-expanded="false" aria-controls="collapse-nft-info">
            <img class="icon" src="@/assets/image/polygon-icon-white.svg">
            <i class="float-end fas fa-chevron-down"></i>
          </button>
          <div class="collapse" id="collapse-nft-info"><div class="card card-body">
            <p class="fw-bold mb-4">Owner: {{artwork.nft.owner}}</p>
            <p class="fw-bold mb-2">About this NFT</p>
            <p class="mb-4" v-text="artwork.nft.description"></p>
            <!-- status -->
            <div class="token-status-tag">
              <div class="icon">
                <img v-if="artwork.nft.blockchain == 'ethereum'" class="h-100" src="@/assets/image/eth-icon.svg">
                <img v-else class="w-100" src="@/assets/image/polygon-icon.svg">
              </div>
              <span v-text="statusTag(artwork.nft.on_chain_status)"></span>
            </div>
            <div v-if="artwork.nft.token_id">
              <div class="token-status-tag">
                <div class="icon"><img class="h-100" src="@/assets/image/opensea-icon.svg"></div>
                <span class="me-2">Connected</span>
                <a :href="artwork.nft.opensea_url" target="_blank" class="btn btn-primary btn-sm d-inline">Open</a>
              </div>
              <div class="token-status-tag">
                <div class="icon"><img class="h-100" src="@/assets/image/rarible-icon.png"></div>
                <span class="me-2">Connected</span>
                <a :href="artwork.nft.rarible_url" target="_blank" class="btn btn-primary btn-sm d-inline">Open</a>
              </div>
              <div class="token-status-tag">
                <div class="icon"><img class="h-100" src="@/assets/image/laze-icon.png"></div>
                <span class="me-2">Connected</span>
                <a :href="artwork.nft.lazycom_url" target="_blank" class="btn btn-primary btn-sm d-inline">Open</a>
              </div>
            </div>
            <div v-else class="token-status-tag">
              <div class="icon"><img class="h-100" src="@/assets/image/opensea-icon.svg"></div>
              <span>Connecting with opensea.io</span>
            </div>
            <!-- token info -->
            <p class="fw-bold mb-2">Details</p>
            <div class="token-info-block">
              <p class="title">Contract Address</p>
              <p v-text="artwork.nft.contract_address"></p>
            </div>
            <div class="token-info-block">
              <p class="title">Token ID</p>
              <p v-text="artwork.nft.token_id"></p>
            </div>
            <div class="token-info-block">
              <p class="title">Token standard</p>
              <p v-text="artwork.nft.token_standard"></p>
            </div>
          </div></div>
        </div>
      </div></div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      artwork: {
        product: {
          created_year: "",
          title: "",
          material: "",
          size: "",
          description: "",
          title: "",
          artist: [],
          isEntity: {
            hasEntityArtwork: false
          },
          isNft: {
            hasNft: false
          }
        }
      }
    }
  },
  created() {
    var id = this.$route.params.id;
    this.axios.get(process.env.VUE_APP_API_URL+'/v1/product/'+id+'/detail').then((response) => {
      if(response.data.code === 0) {
        this.artwork = response.data.data;

        //update url
        if(this.artwork.product.isNft.hasNft) {
          this.artwork.nft.contract_address = this.artwork.nft.contract_address.toLowerCase();
          
          if(this.artwork.nft.blockchain === 'ethereum') {
            this.artwork.nft.opensea_url = 'https://opensea.io/assets/ethereum/'+this.artwork.nft.contract_address+'/'+this.artwork.nft.token_id;
            this.artwork.nft.rarible_url = 'https://rarible.com/token/'+this.artwork.nft.contract_address+':'+this.artwork.nft.token_id+'?tab=details';
            this.artwork.nft.lazycom_url = 'https://lazy.com/'+this.artwork.nft.blockchain+'/'+this.artwork.nft.wallet_address+'/'+this.artwork.nft.contract_address+'/'+this.artwork.nft.token_id;
          } else {
            this.artwork.nft.opensea_url = 'https://opensea.io/assets/matic/'+this.artwork.nft.contract_address+'/'+this.artwork.nft.token_id;
            this.artwork.nft.rarible_url = 'https://rarible.com/token/polygon/'+this.artwork.nft.contract_address+':'+this.artwork.nft.token_id+'?tab=details';
            this.artwork.nft.lazycom_url = 'https://lazy.com/polygon/'+this.artwork.nft.wallet_address+'/'+this.artwork.nft.contract_address+'/'+this.artwork.nft.token_id;
          }
        }

      }
    }).catch(function (error) {
      console.log(error);
    });
  },
  methods: {
    statusTag(value) {
      var statusList = {
        'data-iput': 'Mint failed',
        'awaiting_paid': 'Mint failed',
        'paid': 'Mint in progress',
        'do-on-chain': 'Mint Complete',
        'success': 'Mint Complete'
      };
      return statusList[value];
    },
    getPhoto: function(url) {
      return process.env.VUE_APP_SITE_URL + url;
    }
  }
}
</script>