<template>
  <Header />
    <div class="content-container container">
      <Carousel />
      <PageNav />
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-trinity.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-trinity.jpg">
              <p class="name">Trinity</p>
              <p>Illustrator</p>
              <p>Africa</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-ethan-lord.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-ethan-lord.jpg">
              <p class="name">Ethan Lord</p>
              <p>Illustrator</p>
              <p>Oceania</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-stephen-joe.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-stephen-joe.jpg">
              <p class="name">Stephen Joe</p>
              <p>Watercolor painter</p>
              <p>Asia</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-jim-resh.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-jim-resh.jpg">
              <p class="name">Jim Resh</p>
              <p>Pencil</p>
              <p>Asia</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-marguerite.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-marguerite.jpg">
              <p class="name">Marguerite</p>
              <p>Ink painter</p>
              <p>Africa</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-vinona-rui.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-vinona-rui.jpg">
              <p class="name">Vinona Rui</p>
              <p>Ink painter</p>
              <p>South America</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-nina.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-nina.jpg">
              <p class="name">Nina</p>
              <p>Photographer</p>
              <p>Oceania</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-mona.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-mona.jpg">
              <p class="name">Mona</p>
              <p>Oil painter</p>
              <p>South America</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-white-paine.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-white-paine.jpg">
              <p class="name">White Paine</p>
              <p>Watercolor painter</p>
              <p>North America</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-astrid.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-astrid.jpg">
              <p class="name">Astrid</p>
              <p>Watercolor painter</p>
              <p>Oceania</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-michael.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-michael.jpg">
              <p class="name">Michael</p>
              <p>Sculptor</p>
              <p>South Africa</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-scarlett.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-scarlett.jpg">
              <p class="name">Scarlett</p>
              <p>Illustrator</p>
              <p>Oceania</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-baker.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-baker.jpg">
              <p class="name">Baker</p>
              <p>Other</p>
              <p>Europe</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-annabetti.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-annabetti.jpg">
              <p class="name">Annabetti</p>
              <p>Illustrator</p>
              <p>Europe</p>
          </div>
        </div></div>
        <div class="col-12 col-sm-6 col-md-4"><div class="artist-card">
          <img class="cover w-100" src="@/assets/image/artist/cover-david-carter.jpg">
          <div class="artist-card__content">
            <img class="avatar" src="@/assets/image/artist/avatars-david-carter.jpg">
              <p class="name">David Carter</p>
              <p>Oil painter</p>
              <p>Other</p>
          </div>
        </div></div>
      </div>      
    </div>
  <Footer />
</template>

<script>

import Header from '@/components/Header.vue'
import Carousel from '@/components/Carousel.vue'
import PageNav from '@/components/PageNav.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Carousel,
    PageNav,
    Footer
  }
}
</script>