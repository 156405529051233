<template>
  <Header />
    <div class="content-container container">
      <MemberHeader :profile=profile :edit="false" />
      <div class="">
        <ul class="member-links">
          <li><router-link to="/my_account/bio">My account</router-link></li>
          <li><router-link to="/my_artworks/list">My artwork</router-link></li>
        </ul>
        <div class="row manage-artwork-list">
          <div class="col-12 col-sm-6 col-md-4"><div class="manage-artwork-card create">
            <p>Add an original Artwork</p>
            <button class="btn btn-icon create-btn"><router-link to="/my_artworks/original">
              <img src="@/assets/image/create-icon_48x48.png">
            </router-link></button>
            <p>Sell your artwork as a physical piece or NFT</p>
          </div></div>
          <div class="col-12 col-sm-6 col-md-4" v-for="row in list"><div class="manage-artwork-card">
            <router-link :to="{path: '/artwork_detail/'+row._id}">
              <img :src="getPhoto(row.thumbnail)">
            </router-link>
            <div class="manage-artwork-card__content">
              <router-link :to="{path: '/artwork_detail/'+row._id}">
                <p class="title" v-text="row.title"></p>
              </router-link>
              <div v-if="row.isEntity.hasEntityArtwork" class="link-box clearfix">
                <p class="float-start"><img src="@/assets/image/physical-icon.svg">Physical Artwork</p>
                <button class="btn btn-primary btn-sm float-end" disabled>Add</button>
              </div>
              <div v-if="row.isNft.hasNft" class="link-box clearfix">
                <p class="float-start">
                  <img v-if="row.nft[0].blockchain == 'ethereum'" src="@/assets/image/eth-icon.svg">
                  <img v-else src="@/assets/image/polygon-icon.svg">
                  NFT
                </p>
                <button class="btn btn-primary btn-sm float-end" disabled>Sell</button>
              </div>
              <div class="link-box clearfix">
                <p class="float-start"><img src="@/assets/image/license-icon.svg">Usage License</p>
                <button class="btn btn-primary btn-sm float-end" disabled>Add</button>
              </div>
            </div>
          </div></div>
        </div>
      </div>
    </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import MemberHeader from '@/components/MemberHeader.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    MemberHeader,
    Footer
  },
  data() {
    return{
      profile: {},
      list: []
    }
  },
  mounted() {
    //get profile
    this.axios.get(process.env.VUE_APP_API_URL+'/v1/profile').then((response) => {
      if(response.data.code === 0 && Object.keys(response.data.data).length) {
        this.profile = response.data.data;
      }
    }).catch(function (error) {
      console.log(error);
    });

    //get list
    this.axios.get(process.env.VUE_APP_API_URL+'/v1/profile/products').then((response) => {
      if(response.data.code === 0) {
        this.list = response.data.data
      }
    }).catch(function (error) {
      console.log(error.response.data);
    });
  },
  methods: {
    getPhoto: function(url) {
      return process.env.VUE_APP_SITE_URL + url;
    }
  }
}
</script>