import { createRouter, createWebHashHistory } from "vue-router";

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Artists from '../views/Artists.vue'
import Gallery from '../views/Gallery.vue'
import NFT from '../views/NFT.vue'
import Event from '../views/Event.vue'
import Bio from '../views/Bio.vue'
import MyArtwork from '../views/MyArtwork.vue'
import CreateArtwork from '../views/CreateArtwork.vue'
import EditArtwork from '../views/EditArtwork.vue'
import ArtworkDtl from '../views/ArtworkDtl.vue'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/artists',
      name: 'Artists',
      component: Artists,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/gallery',
      name: 'Art Gallery',
      component: Gallery,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/nft',
      name: 'NFT',
      component: NFT,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/event',
      name: 'Event',
      component: Event,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/artwork/:id',
      name: 'ArtworkDtl',
      component: ArtworkDtl,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/my_account/bio',
      name: 'Bio',
      component: Bio,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/my_artworks/list',
      name: 'MyArtwork',
      component: MyArtwork,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/my_artworks/original',
      name: 'CreateArtwork',
      component: CreateArtwork,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/artwork_detail/:id',
      name: 'EditArtwork',
      component: EditArtwork,
      meta: {
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((to, from) => {
  const { $cookies } = router.app.config.globalProperties;

  $cookies.set('aty_refer', from.path, '1D');
  let isAuthenticated = $cookies.get('aty_token');
  if (to.meta.requiresAuth && !isAuthenticated) {
    return { name: 'Login' };
  }
});


export default router
